import { ChangeEvent, FC, FocusEvent, useEffect } from 'react';
import { useInjection } from 'inversify-react';
import { FormItemType } from '../enums/form-item-type';
import { FormItemBaseProps } from '../interfaces/form-item-base-props';
import { FormItemWithHelpProps } from '../interfaces/form-item-with-help-props';
import { InjectionKeys } from '../../../core/injection.keys';
import { Box, InputAdornment, TextField } from '@mui/material';
import { PropsWithClassname } from '../../../core/types/react';
import { Help } from '../../help';
import styles from '../form-item.module.css';
import { clsx } from 'clsx';
import { IErrorService } from '../../../core/services/error-service';
import { useMount } from '../../../core/hooks/use-mount';
import { observer } from 'mobx-react';
import { FormItemWithLabelProps } from '../interfaces/form-item-with-label-props';
import { IErrorGlobalService } from '../../../core/services/error-global-service';
import { autorun } from 'mobx';

export interface FormItemNumberProps extends FormItemBaseProps<number>, FormItemWithLabelProps, FormItemWithHelpProps {
  type: FormItemType.Number;

  /**
   * Если свойство задано, будет отображать единицу измерения,
   * переданную в этом поле.
   */
  unit?: string;

  /**
   * Определяет, можно ли вводить только целочисленные значения или нет.
   */
  integer?: boolean;
}

/**
 * Поле ввода чисел.
 */
export const FormItemNumber: FC<PropsWithClassname<FormItemNumberProps>> = observer(({
  id,
  label,
  value,
  help,
  unit,
  integer = false,
  readonly = false,
  disabled = false,
  required = false,
  className,

  onChange,
}) => {
  const errorService = useInjection<IErrorService>(InjectionKeys.ErrorService);
  const errorGlobalService = useInjection<IErrorGlobalService>(InjectionKeys.ErrorGlobalService);

  useMount(() => errorService.init(id, required));

  useEffect(() => autorun(() => {
    if (errorGlobalService.inCheckFormFlag != null) {
      errorService.validateNumber(value);
      errorService.validateRequired(value);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [value]);

  const handleChange = ({ target }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let num = integer
      ? parseInt(target.value)
      : parseFloat(target.value);

    errorService.validateNumber(num);
    errorService.validateRequired(num);

    onChange(num);
  }

  const handleFocus = ({ target }: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let num = integer
      ? parseInt(target.value)
      : parseFloat(target.value);

    errorService.refresh();
    errorService.validateNumber(num);
  }

  const handleBlur = ({ target }: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let num = integer
      ? parseInt(target.value)
      : parseFloat(target.value);

    errorService.validateNumber(num);
    errorService.validateRequired(num);
  }

  return (
    <Box className={styles.wrapper}>
      <TextField
        fullWidth
        type='number'
        size='small'
        id={id}
        label={label}
        disabled={disabled}
        required={required}
        className={clsx(styles.textfield, styles.number, className)}
        error={errorService.hasError}
        helperText={errorService.message}
        value={isNaN(value) ? '' : value}

        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}

        InputProps={unit ? {
          readOnly: readonly,
          endAdornment: (
            <InputAdornment position="end">
              {unit}
            </InputAdornment>
          )
        } : { readOnly: readonly }}
      />
      
      {help
        ? <Help content={help} className={styles.help} />
        : null}
    </Box>
  );
});