import { FC } from 'react';
import { useI18n } from '../../core/hooks/use-i18n';
import { useInjection } from 'inversify-react';
import { InjectionKeys } from '../../core/injection.keys';
import { observer } from 'mobx-react';
import { FormColumn } from '../form-builder';
import { FormItemProps, FormItemType } from '../form-item';
import { OrderModel } from '../../core/models/order-model';
import cities from '../../configs/cities.json';
import { DetailModel } from '../../core/models/detail-model';

/**
 * Строитель формы заказа.
 * 
 * В себе содержит только инъекцию бизнес-модели и конфигурацию самой формы, внутри
 * которой полями value и onChange он связывает отображение и изменение данных в форме.
 * 
 * А основной строитель форм на основе указанных параметров уже сам генерирует форму. 
 */
export const OrderFormBuilder: FC = observer(() => {
  const i18n = useI18n();
  const detailModel = useInjection<DetailModel>(InjectionKeys.DetailModel);
  const orderModel = useInjection<OrderModel>(InjectionKeys.OrderModel);

  const formConfig: FormItemProps[] = [
    {
      id: 'detail-name',
      disabled: true,
      type: FormItemType.Text,
      label: i18n.form.detailName,
      value: detailModel.detailName,
      onChange: x => detailModel.updateModel('detailName', x),
    },
    {
      id: 'detail-count',
      disabled: true,
      type: FormItemType.Number,
      unit: i18n.form.countUnit,
      label: i18n.form.detailCount,
      value: detailModel.detailQuantity,
      onChange: x => detailModel.updateModel('detailQuantity', x),
    },
    {
      id: 'response-timeout',
      integer: true,
      required: true,
      type: FormItemType.Number,
      label: i18n.form.responseTimeout,
      help: i18n.form.responseTimeoutHelp,
      unit: i18n.form.dayUnit,
      value: orderModel.applicationResponseTimeout,
      onChange: x => orderModel.updateModel('applicationResponseTimeout', x),
    },
    {
      id: 'offers-quantity',
      required: true,
      integer: true,
      type: FormItemType.Number,
      label: i18n.form.offersQuantity,
      help: i18n.form.offersQuantityHelp,
      unit: i18n.form.countUnit,
      value: orderModel.offersQuantity,
      onChange: x => orderModel.updateModel('offersQuantity', x),
    },
    {
      id: 'trolling-material',
      type: FormItemType.Checkbox,
      label: i18n.form.trollingMaterial,
      help: i18n.form.trollingMaterialHelp,
      value: orderModel.isTollingMaterial,
      onChange: x => orderModel.updateModel('isTollingMaterial', x),
    },
    {
      id: 'shipping-at',
      type: FormItemType.Date,
      label: i18n.form.shippingAt,
      required: true,
      help: i18n.form.shippingAtHelp,
      value: orderModel.shippingAt,
      onChange: x => orderModel.updateModel('shippingAt', x),
    },
    {
      id: 'region-search',
      type: FormItemType.Autocomplete,
      label: i18n.form.regionSearch,
      help: i18n.form.regionSearchHelp,
      options: cities,
      value: orderModel.regionSearch as string,
      onChange: x => orderModel.updateModel('regionSearch', x as string),
      anchorOrigin: { vertical: 'top', horizontal: 'left' }
    },
    {
      id: 'limit-price',
      type: FormItemType.Number,
      label: i18n.form.limitPrice,
      help: i18n.form.limitPriceHelp,
      unit: i18n.form.roubleUnit,
      value: orderModel.limitingPrice,
      onChange: x => orderModel.updateModel('limitingPrice', x),
    },
    {
      id: 'additional-comment',
      type: FormItemType.Textarea,
      label: i18n.form.additionalCommentLong,
      help: i18n.form.additionalCommentHelp,
      value: orderModel.applicationComment,
      onChange: x => orderModel.updateModel('applicationComment', x),
    },
  ];

  return <FormColumn config={formConfig} />;
});
