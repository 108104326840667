import { FC, SyntheticEvent, useEffect } from 'react';
import { useInjection } from 'inversify-react';
import { FormItemType } from '../enums/form-item-type';
import { FormItemBaseProps } from '../interfaces/form-item-base-props';
import { FormItemWithHelpProps } from '../interfaces/form-item-with-help-props';
import { InjectionKeys } from '../../../core/injection.keys';
import { Autocomplete, AutocompleteChangeReason, Box, FilterOptionsState, TextField } from '@mui/material';
import { PropsWithClassname } from '../../../core/types/react';
import { Help } from '../../help';
import styles from '../form-item.module.css';
import { clsx } from 'clsx';
import { useMount } from '../../../core/hooks/use-mount';
import { FormItemWithLabelProps } from '../interfaces/form-item-with-label-props';
import { observer } from 'mobx-react';
import { IErrorService } from '../../../core/services/error-service';
import { IErrorGlobalService } from '../../../core/services/error-global-service';
import { autorun } from 'mobx';

export interface FormItemAutocompleteProps extends FormItemBaseProps<string | string[]>, FormItemWithLabelProps, FormItemWithHelpProps {
  type: FormItemType.Autocomplete;

  /**
   * Список значений поля.
   */
  options: string[];

  /**
   * Определеяет возможность множественного выбора.
   */
  multiple?: boolean;

  /**
   * Определяет значение по умолчанию.
   */
  defaultValue?: string | string[];
  anchorOrigin?: {
    vertical: 'bottom' | 'top';
    horizontal: 'left' | 'right';
  };

}

/**
 * Поле ввода текста с выпадающим списком.
 */
export const FormItemAutocomplete: FC<PropsWithClassname<FormItemAutocompleteProps>> = observer(({
  id,
  label,
  value,
  defaultValue,
  multiple,
  options,
  help,
  readonly = false,
  disabled = false,
  required = false,
  className,

  onChange,
}) => {
  const errorService = useInjection<IErrorService>(InjectionKeys.ErrorService);
  const errorGlobalService = useInjection<IErrorGlobalService>(InjectionKeys.ErrorGlobalService);

  useMount(() => errorService.init(id, required));

  useEffect(() => autorun(() => {
    if (errorGlobalService.inCheckFormFlag != null) {
      errorService.validateRequired(value);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [value]);

  const handleFocus = () => errorService.refresh();

  const handleBlur = () => errorService.validateRequired(value)

  const handleInput = (options: string[], { inputValue }: FilterOptionsState<string>) => {
    return !inputValue.length ? options : options.filter(x => x.toLowerCase().startsWith(inputValue.trim().toLowerCase()))
  };

  const handleInputChange = (_: SyntheticEvent<Element, Event>, value: string | string[] | null, reason: AutocompleteChangeReason) => {
    errorService.validateRequired(value);

    if (value == null) {
      onChange(multiple ? [] : '');
    } else {
      onChange(value);
    }
  };

  return (
    <Box className={styles.wrapper}>
      <Autocomplete
        freeSolo
        multiple={multiple}
        fullWidth
        disabled={disabled}
        readOnly={readonly}
        size='small'
        id={id}
        className={clsx(styles.textfield, className)}
        options={options}
        defaultValue={defaultValue}
        value={value}
        onChange={handleInputChange}
        filterOptions={handleInput}
        renderInput={params => (
          <TextField
            {...params}
            label={label}
            required={required}
            error={errorService.hasError}
            helperText={errorService.message}

            onFocus={handleFocus}
            onBlur={handleBlur}
          />
        )}
      />
      
      {help
        ? <Help content={help} className={styles.help} />
        : null}
    </Box>
  );
});