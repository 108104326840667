import { useInjection } from 'inversify-react';
import { FC } from 'react';
import { InjectionKeys } from '../../core/injection.keys';
import { FormItemProps, FormItemType } from '../form-item';
import { useI18n } from '../../core/hooks/use-i18n';
import { FormColumn } from '../form-builder';
import { observer } from 'mobx-react';
import { ProviderModel } from '../../core/models/provider-model';
import cities from '../../configs/cities.json';
import techProcesses from '../../configs/tech-process.json';

/**
 * Строитель формы производителя.
 * 
 * В себе содержит только инъекцию бизнес-модели и конфигурацию самой формы, внутри
 * которой полями value и onChange он связывает отображение и изменение данных в форме.
 * 
 * А основной строитель форм на основе указанных параметров уже сам генерирует форму. 
 */
export const ProviderFormBuilder: FC = observer(() => {
  const i18n = useI18n();
  const model = useInjection<ProviderModel>(InjectionKeys.ProviderModel);

  const formConfig: FormItemProps[] = [
    {
      id: 'company',
      required: true,
      type: FormItemType.Text,
      label: i18n.form.providerName,
      value: model.companyName,
      onChange: x => model.updateModel('companyName', x),
    },
    {
      id: 'inn',
      required: true,
      type: FormItemType.INN,
      label: i18n.form.inn,
      value: model.INN,
      onChange: x => model.updateModel('INN', x),
    },
    {
      id: 'process-types',
      required: true,
      multiple: true,
      type: FormItemType.Autocomplete,
      label: i18n.form.processTypes,
      options: techProcesses,
      value: model.technologicalProcessList,
      onChange: x => model.updateModel('technologicalProcessList', x as string[]),
    },
    {
      id: 'address',
      required: true,
      type: FormItemType.Autocomplete,
      label: i18n.form.city,
      options: cities,
      value: model.cityLocation as string,
      onChange: x => model.updateModel('cityLocation', x as string),
    },
    {
      id: 'phone',
      type: FormItemType.Phone,
      label: i18n.form.phone,
      required: true,
      value: model.phone,
      onChange: x => model.updateModel('phone', x),
    },
    {
      id: 'email',
      type: FormItemType.Email,
      label: i18n.form.email,
      required: true,
      value: model.email,
      onChange: x => model.updateModel('email', x),
    },
    {
      id: 'description',
      type: FormItemType.Textarea,
      label: i18n.form.providerDescription,
      value: model.serviceAndProductDescription,
      onChange: x => model.updateModel('serviceAndProductDescription', x),
    },
    {
      id: 'service',
      type: FormItemType.Textarea,
      label: i18n.form.services,
      value: model.productionDescription,
      onChange: x => model.updateModel('productionDescription', x),
    },
  ];

  return <FormColumn config={formConfig} />;
});
