import { HelpOutline } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { FC } from 'react';
import { PropsWithClassname } from '../core/types/react';

interface HelpProps {
  /**
   * Текст подсказки.
   */
  content: string;
}

/**
 * Компонент, который отвечает за отображение всплывающих подсказок.
 */
export const Help: FC<PropsWithClassname<HelpProps>> = ({ content, className }) => (
  <Tooltip  
    title={content}
    enterTouchDelay={0}
    leaveTouchDelay={15000}
  >
    <HelpOutline
      className={className}
      color="action"
    />
  </Tooltip>
);