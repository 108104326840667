import { injectable } from 'inversify';
import { makeAutoObservable } from 'mobx';
import { IErrorGlobalService } from './interfaces/error-global-service';
import { Nullable } from '../../types/common';

@injectable()
export class ErrorGlobalService implements IErrorGlobalService {
  public fields: Record<string, boolean> = {};
  public inCheckFormFlag: Nullable<boolean> = null;

  public constructor() {
    makeAutoObservable(this);
  }

  public get hasErrors(): boolean {
    return Object.keys(this.fields).some(x => this.fields[x]);
  }

  public add(id: string): void {
    this.fields[id] = false;
  }

  public set(id: string, value: boolean): void {
    this.fields[id] = value;
  }

  public checkFormStart(): void {
    this.inCheckFormFlag = !this.inCheckFormFlag;
  }

  public clear(): void {
    this.fields = {};
    this.inCheckFormFlag = null;
  }
}