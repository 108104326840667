import { FC, FocusEvent, useEffect, useState } from 'react';
import { FormItemType } from '../enums/form-item-type';
import { FormItemBaseProps } from '../interfaces/form-item-base-props';
import { FormItemWithHelpProps } from '../interfaces/form-item-with-help-props';
import { Box } from '@mui/material';
import { Nullable } from '../../../core/types/common';
import { PropsWithClassname } from '../../../core/types/react';
import { Help } from '../../help';
import styles from '../form-item.module.css';
import { clsx } from 'clsx';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import { useInjection } from 'inversify-react';
import { IErrorService } from '../../../core/services/error-service';
import { InjectionKeys } from '../../../core/injection.keys';
import { useMount } from '../../../core/hooks/use-mount';
import { FormItemWithLabelProps } from '../interfaces/form-item-with-label-props';
import { observer } from 'mobx-react';
import { IErrorGlobalService } from '../../../core/services/error-global-service';
import { autorun } from 'mobx';

export interface FormItemDateProps extends FormItemBaseProps<Nullable<Dayjs>>, FormItemWithLabelProps, FormItemWithHelpProps {
  type: FormItemType.Date;
}

/**
 * Поле ввода или выбора даты. Для форматирования даты использует Dayjs, а для выбора
 * даты используется DatePicker из @mui/x-date-pickers.
 */
export const FormItemDate: FC<PropsWithClassname<FormItemDateProps>> = observer(({
  id,
  label,
  value,
  help,
  readonly = false,
  disabled = false,
  required = false,
  className,

  onChange,
}) => {
  const [open, setOpen] = useState(false);
  const [defalutValue, setDefaultValue] = useState(dayjs());
  const errorService = useInjection<IErrorService>(InjectionKeys.ErrorService);
  const errorGlobalService = useInjection<IErrorGlobalService>(InjectionKeys.ErrorGlobalService);

  useMount(() => errorService.init(id, required));

  useEffect(() => autorun(() => {
    if (errorGlobalService.inCheckFormFlag != null && required) {
      errorService.validateDate(value);
      errorService.validateRequired(value);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [value, required]);

  useMount(() => {
    errorService.init(id, required)

    if (value) setDefaultValue(value);
  });

  const handleChange = (value: Nullable<Dayjs>) => {
    errorService.validateDate(value);
    errorService.validateRequired(value);

    onChange(value);
  }

  const handleFocus = ({ target }: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const date = dayjs(target.value, 'DD.MM.YYYY');

    errorService.refresh();
    errorService.validateDate(date);
  }

  const handleBlur = ({ target }: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const date = dayjs(target.value, 'DD.MM.YYYY');

    errorService.validateDate(date);
    errorService.validateRequired(date);
  }

  return (
    <Box className={styles.wrapper}>
      <DatePicker
        label={label}
        disabled={disabled}
        readOnly={readonly}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        format='DD.MM.YYYY'
        className={clsx(styles.textfield, className)}
        defaultValue={defalutValue}
        value={value ?? defalutValue}

        onChange={handleChange}

        slotProps={{
          textField: {
            fullWidth: true,
            size: 'small',
            id,
            required,
            className: clsx(styles.textfield, className),
            error: errorService.hasError,
            helperText: errorService.message,

            onClick: () => setOpen(true),
            onFocus: handleFocus,
            onBlur: handleBlur,
          }
        }}
      />
      
      {help
        ? <Help content={help} className={styles.help} />
        : null}
    </Box>
  );
});