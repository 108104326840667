/**
 * Тип компонента формы.
 */
export const enum FormItemType {
  /**
   * Однострочное текстовое поле.
   */
  Text,

  /**
   * Многострочное текстовое поле.
   */
  Textarea,

  /**
   * Текстовое поле с возможностью заполнения из списка.
   */
  Autocomplete,

  /**
   * Поле ввода ИНН.
   */
  INN,

  /**
   * Поле ввода номера телефона.
   */
  Phone,

  /**
   * Поле ввода электронной почты.
   */
  Email,

  /**
   * Поле выбора/ввода даты.
   */
  Date,

  /**
   * Числовое поле.
   */
  Number,

  /**
   * Стандартный чекбокс.
   */
  Checkbox,

  /**
   * Поле загрузки файлов.
   */
  File,
}