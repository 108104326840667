import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import { routes } from './routes';
import { ThemeProvider } from '@mui/material';
import { theme } from './theme';
import { LocalizationProvider, ruRU } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/ru';
import { Provider } from 'inversify-react';
import { container } from './core/inversify.config';

export const Application: FC = () => (
  <ThemeProvider theme={theme}>
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      adapterLocale='ru'
      localeText={ruRU.components.MuiLocalizationProvider.defaultProps.localeText}
    >
      <Provider container={container}>
        <Routes>
          {routes.map(({ route, Form }) => (
            <Route
              key={route}
              path={route}
              element={<Form route={route} />}
            />
          ))}
        </Routes>
      </Provider>
    </LocalizationProvider>
  </ThemeProvider>
);
