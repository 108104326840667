import { FC, useState } from 'react';
import { Wrapper } from '../wrapper';
import { Header } from '../header';
import { useI18n } from '../../core/hooks/use-i18n';
import { useInjection } from 'inversify-react';
import { InjectionKeys } from '../../core/injection.keys';
import { observer } from 'mobx-react';
import { IErrorGlobalService } from '../../core/services/error-global-service';
import { useMount } from '../../core/hooks/use-mount';
import { DetailFormBuilder } from './detail-form-builder';
import { DetailModel } from '../../core/models/detail-model';
import { LoadingButton } from '@mui/lab';
import type { ITelegramService } from '../../core/services/telegram-service';

/**
 * Форма детали.
 * 
 * При инициализации обращается к бизнес-модели с попыткой получить имеющиеся
 * в базе данных данные детали. Если таких нет – то форма будет пустой. Если данные есть –
 * форма заполнится автоматически.
 * 
 * После завершения регистрации детали вызывает метод для закрытия текущего окна.
 * 
 * Все формы работают на основе строителя форм: есть обертка, которая отвечает за
 * внешний вид + сабмиттинг, есть внутренняя форма, которая задают конфигурацию
 * для строителя форм с применением бизнес-моделей.
 */
export const DetailForm: FC = observer(() => {
  const i18n = useI18n();
  const model = useInjection<DetailModel>(InjectionKeys.DetailModel);
  const errorGlobalService = useInjection<IErrorGlobalService>(InjectionKeys.ErrorGlobalService);
  const telegramService = useInjection<ITelegramService>(InjectionKeys.TelegramService);
  const [isLoading, setIsLoading] = useState(false);

  useMount(() => {
    const fetch = async () => {
      await model.get();
    };

    fetch();

    return () => errorGlobalService.clear();
  });

  const handleSubmit = async () => {
    errorGlobalService.checkFormStart();
  if (model.drawings === null){
    alert('Пожалуйста, выберите файл')
    return
  }
    if (!errorGlobalService.hasErrors) {

      setIsLoading(true);


      const id = await model.create();

      setIsLoading(false);

      if (id > -1) {
        model.setIsManualFill();
        telegramService.close();
      }
    }
  }

  return (
    <Wrapper>
      <Header>{i18n.createDetail.title}</Header>

      <DetailFormBuilder />
  
      <LoadingButton
        loading={isLoading}
        variant='contained'
        color='primary'
        disabled={errorGlobalService.hasErrors}
        disableElevation={true}
        sx={{ marginTop: '12px' }}
        onClick={handleSubmit}
      >
        {i18n.form.submit2}
      </LoadingButton>
    </Wrapper>
  );
});
