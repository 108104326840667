import { FC } from 'react';
import { ContactForm } from './features/contact-form';
import { ProviderForm } from './features/provider-form';
import { OfferForm } from './features/offer-form';
import { OrderForm } from './features/order-form';
import { DetailForm } from './features/detail-form';

export const routes: Route[] = [
  {
    route: '/provider',
    Form: ProviderForm,
  },
  {
    route: '/offer',
    Form: OfferForm,
  },
  {
    route: '/order',
    Form: OrderForm,
  },
  {
    route: '/detail',
    Form: DetailForm,
  },
  {
    route: '/contact',
    Form: ContactForm,
  }
];

type Route = {
  route: `/${string}`,
  Form: FC<FormProps>,
}

export type FormProps = Omit<Route, 'Form'>