import { FC, useState } from 'react';
import { Wrapper } from '../wrapper';
import { Header } from '../header';
import { useI18n } from '../../core/hooks/use-i18n';
import { Button } from '@mui/material';
import { useInjection } from 'inversify-react';
import { InjectionKeys } from '../../core/injection.keys';
import { observer } from 'mobx-react';
import { IErrorGlobalService } from '../../core/services/error-global-service';
import { useMount } from '../../core/hooks/use-mount';
import { OrderModel } from '../../core/models/order-model';
import { OrderFormBuilder } from './order-form-builder';
import { Description } from '../description';
import { useNavigate } from 'react-router-dom';
import { DetailModel } from '../../core/models/detail-model';
import { LoadingButton } from '@mui/lab';
import type { ITelegramService } from '../../core/services/telegram-service';

/**
 * Форма создания заказа.
 * 
 * При инициализации обращается к бизнес-модели детали и получает все данные о
 * детали.
 * 
 * Так же есть возможность вернуться на форму детали и изменить параметры.
 * 
 * После завершения регистрации заказа вызывает метод для закрытия текущего окна.
 * 
 * Все формы работают на основе строителя форм: есть обертка, которая отвечает за
 * внешний вид + сабмиттинг, есть внутренняя форма, которая задают конфигурацию
 * для строителя форм с применением бизнес-моделей.
 */
export const OrderForm: FC = observer(() => {
  const i18n = useI18n();
  const navigate = useNavigate();
  const detailModel = useInjection<DetailModel>(InjectionKeys.DetailModel);
  const orderModel = useInjection<OrderModel>(InjectionKeys.OrderModel);
  const errorGlobalService = useInjection<IErrorGlobalService>(InjectionKeys.ErrorGlobalService);
  const telegramService = useInjection<ITelegramService>(InjectionKeys.TelegramService);
  const [isLoading, setIsLoading] = useState(false);

  useMount(() => {
    if (!detailModel.isManualFill) {
      const fetch = async () => {
        await detailModel.get();
        await orderModel.get();
      }

      fetch();
    }

    return () => errorGlobalService.clear();
  });

  const handleSubmit = async () => {
    errorGlobalService.checkFormStart();

    if (!errorGlobalService.hasErrors) {
      setIsLoading(true);

      const order = await orderModel.create();

      setIsLoading(false);

      if (order) {
        telegramService.close();
      }
    }
  }

  return (
    <Wrapper>
      <Header>{i18n.createOrder.title}</Header>
      <Description>{i18n.createOrder.description}</Description>

      <OrderFormBuilder />
  
      <LoadingButton
        loading={isLoading}
        variant='contained'
        color='primary'
        disabled={errorGlobalService.hasErrors}
        disableElevation={true}
        sx={{ marginTop: '12px' }}
        onClick={handleSubmit}
      >
        {i18n.form.next}
      </LoadingButton>

      <Button
        variant='contained'
        color='primary'
        disableElevation={true}
        sx={{ marginTop: '12px' }}
        onClick={() => navigate({ pathname: '/detail', search: window.location.search })}
      >
        {i18n.form.prev}
      </Button>
    </Wrapper>
  );
});
