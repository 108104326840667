import { makeAutoObservable } from 'mobx';
import { Nullable } from '../../types/common';
import { inject, injectable } from 'inversify';
import { InjectionKeys } from '../../injection.keys';
import { type IAPIService } from '../../services/api-service';
import type { ITelegramService } from '../../services/telegram-service';
import dayjs, { Dayjs } from 'dayjs';
import { IOrderModel } from './interfaces/order-model';
import { omitEmptyProps } from '../../helpers/omit-empty-props';
import { DetailModel } from '../detail-model';
import {IBotsrvAddOrderParams, IOrder} from '../../services/api-service/client';

/**
 * Бизнес-модель заказа. Является реактивной моделью, то есть
 * все изменения полей провоцируют изменение интерфейса.
 */
@injectable()
export class OrderModel implements IOrderModel {
  public applicationComment = '';
  public applicationKey = '';
  public applicationResponseTimeout = NaN;
  public email = '';
  public isTollingMaterial = false;
  public limitingPrice = NaN;
  public offersQuantity = NaN;
  public offersReceivedCount = NaN;
  public phone = '';
  public productionRequestedCount = NaN;
  public shippingAt: Nullable<Dayjs>;
  public telegramUsername = '';
  public unitServiceCost = NaN;
  public unitTotalCost = NaN;
  public regionSearch = '';

  /**
   * Мемоизированный номер заказа.
   */
  private _orderId: Nullable<number>;

  @inject(InjectionKeys.APIService)
  private _apiService: IAPIService = null as any;

  @inject(InjectionKeys.DetailModel)
  private _detailModel: DetailModel = null as any;

  @inject(InjectionKeys.TelegramService)
  private _telegramService: ITelegramService = null as any;

  public constructor() {
    makeAutoObservable(this);

    this._getParams();
  }

  /**
   * Обновляет значение поля в бизнес-модели.
   */
  public updateModel<K extends keyof IOrderModel>(key: K, value: this[K]): void {
    this[key] = value;
  }

  /**
   * Отправляет запрос в АПИ на регистрацию нового заказа, передавая все
   * заполненные данные из текущей бизнес-модели.
   * 
   * @throws Не найден идентфикатор вашего заказа
   */
  public async create(): Promise<IOrder | void> {
    if (!this._orderId) {
      alert('Не найден идентфикатор вашего заказа');

			return this._telegramService.close();
    }

    const order = await this.toAPIParams(this._orderId);

    return await this._apiService.api.botsrv.addOrder(order);
  }

  /**
   * Отправляет запрос в АПИ, и в случае, если пришел ответ – заполняет
   * поля бизнес-модели вернувшимеся данными.
   */
  public async get(): Promise<void> {
    const orderId = this._getOrderId();

    if (orderId) {
      const res = await this._apiService.api.botsrv.getOrder({ orderId });

      if (res) {
        this.fromAPIResponse(res);
      }
    }
  }

  /**
   * Возвращает и сохраняет номер заказа из параметров URL.
   */
  private _getOrderId(): Nullable<number> {
    if (!this._orderId) {
      const queryParameters = new URLSearchParams(window.location.search);
      const orderId = queryParameters.get('orderId');

      this._orderId = !!orderId ? Number(orderId) : null;
    }

    return this._orderId;
  }


  /**
   * Преобразует модель бэкенда в текущую бизнес-модель.
   *
   * При изменениях на бэкенде важно сюда так же внести изменения – такие, как
   * добавление или удаление полей.
   */
  public fromAPIResponse(dto: IOrder): void {
    this.applicationComment = dto.ApplicationComment ?? this.applicationComment;
    this.applicationKey =  dto.ApplicationKey ?? this.applicationKey;
    this.applicationResponseTimeout = dto.ApplicationResponseTimeout ?? this.applicationResponseTimeout;
    this.email = dto.Email ?? this.email;
    this.isTollingMaterial = dto.IsTollingMaterial ?? this.isTollingMaterial;
    this.limitingPrice = dto.LimitingPrice ?? this.limitingPrice;
    this.offersQuantity = dto.OffersQuantity ?? this.offersQuantity;
    this.offersReceivedCount = dto.OffersReceivedCount ?? this.offersReceivedCount;
    this.phone = dto.Phone ?? this.phone;
    this.regionSearch = dto.RegionSearch ?? this.regionSearch;
    this.productionRequestedCount = dto.ProductionRequestedCount ?? this.productionRequestedCount;
    this.shippingAt = dayjs(dto.ShippingAt) ?? this.shippingAt;
    this.telegramUsername = dto.TelegramUsername ?? this.telegramUsername;
    this.unitServiceCost = dto.UnitServiceCost ?? this.unitServiceCost;
    this.unitTotalCost = dto.UnitTotalCost ?? this.unitTotalCost;
  }


  /**
   * Преобразует бизнес-модель в модель бэкенда.
   * 
   * При изменениях на бэкенде важно сюда так же внести изменения – такие, как
   * добавление или удаление полей.
   */
  public async toAPIParams(orderId: number): Promise<IBotsrvAddOrderParams> {
    const detailAPIParams = await this._detailModel.toAPIParams();

    return {
      orderId,
      order: omitEmptyProps({
        ApplicationComment: this.applicationComment,
        ApplicationKey: this.applicationKey,
        ApplicationResponseTimeout: this.applicationResponseTimeout,
        Email: this.email,
        IsTollingMaterial: this.isTollingMaterial,
        LimitingPrice: this.limitingPrice,
        OffersQuantity: this.offersQuantity,
        OffersReceivedCount: this.offersReceivedCount,
        Phone: this.phone,
        RegionSearch: this.regionSearch,
        ProductionRequestedCount: this.productionRequestedCount,
        ShippingAt: this.shippingAt ? this.shippingAt.toISOString() : '',
        TelegramUsername: this._telegramService.user?.usernames,
        UnitServiceCost: this.unitServiceCost,
        UnitTotalCost: this.unitTotalCost,
        Detail: detailAPIParams.detail,
      })
    };
  }

  /**
   * Возвращает и сохраняет номер заказа из параметров URL.
   */
  private _getParams(): void {
    const queryParameters = new URLSearchParams(window.location.search);

    const orderId = queryParameters.get('orderId');
    const applicationKey = queryParameters.get('applicationKey');

    this._orderId = !!orderId ? Number(orderId) : null;

    this.applicationKey = applicationKey ?? '';
  }
}