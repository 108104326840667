/**
 * Ключи сервисов и моделей для контейнера инверсий зависимостей.
 * 
 * Необходимо, поскольку в TypeScript нет возможности создать инверсию зависимостей
 * на основе только типов, поскольку типы используются только при разработке,
 * в то время как в рантайме работает голый JavaScript, в котором типизация динамическая.
 */
export const InjectionKeys = {
  ConfigService: Symbol.for('ConfigService'),
  APIService: Symbol.for('APIService'),
  TelegramService: Symbol.for('TelegramService'),
  ValidatorService: Symbol.for('ValidatorService'),
  ErrorService: Symbol.for('ErrorService'),
  ErrorGlobalService: Symbol.for('ErrorGlobalService'),

  ContactModel: Symbol.for('ContactModel'),
  ProviderModel: Symbol.for('ProviderModel'),
  OfferModel: Symbol.for('OfferModel'),
  OrderModel: Symbol.for('OrderModel'),
  DetailModel: Symbol.for('DetailModel'),
};