import { useInjection } from 'inversify-react';
import { FC } from 'react';
import { ContactModel } from '../../core/models/contact-model/contact-model';
import { InjectionKeys } from '../../core/injection.keys';
import { FormItemProps, FormItemType } from '../form-item';
import { useI18n } from '../../core/hooks/use-i18n';
import { IConfigService } from '../../core/services/config-service';
import { Link } from '@mui/material';
import styles from './contact-form.module.css';
import { FormColumn } from '../form-builder';
import { observer } from 'mobx-react';

/**
 * Строитель формы контактов.
 * 
 * В себе содержит только инъекцию бизнес-модели и конфигурацию самой формы, внутри
 * которой полями value и onChange он связывает отображение и изменение данных в форме.
 * 
 * А основной строитель форм на основе указанных параметров уже сам генерирует форму. 
 */
export const ContactFormBuilder: FC = observer(() => {
  const i18n = useI18n();
  const configService = useInjection<IConfigService>(InjectionKeys.ConfigService);
  const model = useInjection<ContactModel>(InjectionKeys.ContactModel);

  const formConfig: FormItemProps[] = [
    {
      id: 'phone',
      required: true,
      type: FormItemType.Phone,
      label: i18n.form.phone,
      value: model.phone,
      onChange: x => model.updateModel('phone', x),
    },
    {
      id: 'email',
      required: true,
      type: FormItemType.Email,
      label: i18n.form.email,
      value: model.email,
      onChange: x => model.updateModel('email', x),
    },
    {
      id: 'privacy',
      required: true,
      type: FormItemType.Checkbox,
      label: (
        <>
          {i18n.form.accept}&nbsp;
          <Link href={configService.privacy} target='_blank'>
            {i18n.form.privacy}
          </Link>
        </>
      ),
      value: model.privacy,
      onChange: x => model.updateModel('privacy', x),
    },
    {
      id: 'terms',
      required: true,
      type: FormItemType.Checkbox,
      className: styles.terms,
      label: (
        <>
          {i18n.form.accept}&nbsp;
          <Link href={configService.terms} target='_blank'>
            {i18n.form.terms}
          </Link>
        </>
      ),
      value: model.terms,
      onChange: x => model.updateModel('terms', x),
    },
  ];

  return <FormColumn config={formConfig} />;
});
