import { FC } from 'react';
import { useI18n } from '../../core/hooks/use-i18n';
import { useInjection } from 'inversify-react';
import { InjectionKeys } from '../../core/injection.keys';
import { observer } from 'mobx-react';
import { FormColumn } from '../form-builder';
import { OfferModel } from '../../core/models/offer-model';
import { FormItemProps, FormItemType } from '../form-item';
import techProcesses from '../../configs/tech-process.json';

/**
 * Строитель формы оффера.
 * 
 * В себе содержит только инъекцию бизнес-модели и конфигурацию самой формы, внутри
 * которой полями value и onChange он связывает отображение и изменение данных в форме.
 * 
 * А основной строитель форм на основе указанных параметров уже сам генерирует форму. 
 */
export const OfferFormBuilder: FC = observer(() => {
  const i18n = useI18n();
  const model = useInjection<OfferModel>(InjectionKeys.OfferModel);

  const formConfig: FormItemProps[] = [
    {
      id: 'detail-count',
      required: true,
      type: FormItemType.Number,
      label: i18n.form.detailCount,
      unit: i18n.form.countUnit,
      value: model.detailQuantity,
      onChange: x => model.updateModel('detailQuantity', x),
    },
    {
      id: 'trolling-material',
      type: FormItemType.Checkbox,
      label: i18n.form.trollingMaterial,
      help: i18n.form.trollingMaterialHelp,
      value: model.isTollingMaterial,
      onChange: x => model.updateModel('isTollingMaterial', x),
    },
    {
      id: 'process-types',
      required: true,
      multiple: true,
      type: FormItemType.Autocomplete,
      options: techProcesses,
      label: i18n.form.technologicalProcess,
      value: model.technologicalProcessList,
      onChange: x => model.updateModel('technologicalProcessList', x as string[]),
    },
    {
      id: 'shipping-date',
      required: true,
      type: FormItemType.Date,
      label: i18n.form.shippingDate,
      value: model.shippingAt,
      onChange: x => model.updateModel('shippingAt', x),
    },
    {
      id: 'service-price',
      type: FormItemType.Number,
      label: i18n.form.servicePrice,
      unit: i18n.form.roubleUnit,
      value: model.unitServiceCost,
      onChange: x => model.updateModel('unitServiceCost', x),
    },
    {
      id: 'total-price',
      type: FormItemType.Number,
      label: i18n.form.totalPrice,
      unit: i18n.form.roubleUnit,
      value: model.totalUnitCost,
      onChange: x => model.updateModel('totalUnitCost', x),
    },
    {
      id: 'delivery-cost',
      type: FormItemType.Number,
      label: i18n.form.deliveryCost,
      unit: i18n.form.roubleUnit,
      value: model.deliveryCost,
      onChange: x => model.updateModel('deliveryCost', x),
    },
    {
      id: 'total-weight',
      type: FormItemType.Number,
      label: i18n.form.totalWeight,
      unit: i18n.form.weightUnit,
      value: model.unitWeight,
      onChange: x => model.updateModel('unitWeight', x),
    },
    {
      id: 'additional-comment',
      type: FormItemType.Textarea,
      label: i18n.form.additionalComment,
      help: i18n.form.additionalCommentHelp,
      value: model.comment,
      onChange: x => model.updateModel('comment', x),
    },
  ];

  return <FormColumn config={formConfig} />;
});
