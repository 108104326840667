/**
 * Перечисление, которое в валидаторе используется для типа ошибки в
 * результате проверки значения.
 */
export const enum ValidatorIssueType {
  /**
   * Число меньше нуля.
   */
  LessThanZero = 'lessThanZero',

  /**
   * Пустая строка или число меньше нуля.
   */
  Empty = 'empty',

  /**
   * Неверный ИНН.
   */
  INN = 'inn',

  /**
   * Неверный номер телефона.
   */
  Phone = 'phone',

  /**
   * Неверный адрес электронной почты.
   */
  Email = 'email',

  /**
   * Неверная дата.
   */
  Date = 'date',

  /**
   * Чекбокс не отмечен.
   */
  Checkbox = 'checkbox',
}