import { Nil } from '../types/common';

/**
 * Мета-тип для удаления пустых полей в объектах. Возвращает тип, основанный
 * на переданном объекте, который не содержит поля с типом null, undefined,
 * или же не имеет в себе пустую строку.
 */
type OmitEmptyProps<T extends object> = {
  [K in keyof T]: T[K] extends Nil
    ? never
    : T[K] extends ''
      ? never
      : T[K]
};

/**
 * Возвращает объект, который не содержит поля со значениями null, undefined,
 * или же не имеет в себе пустую строку. Так же, поля, которые имеют тип NaN,
 * тоже будут удалены.
 */
export const omitEmptyProps = <T extends object>(obj: T): OmitEmptyProps<T> => {
  const newObj = {} as T;

  Object.keys(obj).forEach(key => {
    const data = obj[key as keyof T];
    
    if (data != null && data !== '' && !(typeof data === 'number' && isNaN(data))) {
      newObj[key as keyof T] = data;
    }
  });

  return newObj as OmitEmptyProps<T>;
}