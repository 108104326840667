import { makeAutoObservable } from 'mobx';
import { inject, injectable } from 'inversify';
import { InjectionKeys } from '../../injection.keys';
import { type IAPIService } from '../../services/api-service';
import { IProviderModel } from './interfaces/provider-model';
import { omitEmptyProps } from '../../helpers/omit-empty-props';
import { IProduction } from '../../services/api-service/client';

/**
 * Бизнес-модель производителя. Является реактивной моделью, то есть
 * все изменения полей провоцируют изменение интерфейса.
 */
@injectable()
export class ProviderModel implements IProviderModel {
  public companyName = '';
  public serviceAndProductDescription = '';
  public productionDescription = '';
  public INN = '';
  public cityLocation = '';
  public phone = '';
  public email = '';
  public technologicalProcessList: string[] = [];

  /**
   * Возвращает значение, определяющее, есть ли в базе данных данный производитель
   * или нет.
   */
  public isExists = false;

  @inject(InjectionKeys.APIService)
  private _apiService: IAPIService = null as any;

  public constructor() {
    makeAutoObservable(this);
  }

  /**
   * Обновляет значение поля в бизнес-модели.
   */
  public updateModel<K extends keyof IProviderModel>(key: K, value: this[K]): void {
    this[key] = value;
  }

  /**
   * Отправляет запрос в АПИ, и в случае, если пришел ответ – заполняет
   * поля бизнес-модели вернувшимеся данными.
   */
  public async get(): Promise<IProduction> {
    const res = await this._apiService.api.botsrv.getProduction();

    if (res) {
      this.fromAPIResponse(res);

      this.isExists = true;
    }

    return res;
  }

  /**
   * Отправляет запрос в АПИ на регистрацию нового производителя, передавая все
   * заполненные данные из текущей бизнес-модели.
   */
  public async create(): Promise<void> {
    await this._apiService.api.botsrv.addProvider({ p: this.toAPIParams() });
  }

  /**
   * Отправляет запрос в АПИ на изменение существующего производителя, передавая все
   * заполненные данные из текущей бизнес-модели.
   */
  public async change(): Promise<void> {
    await this._apiService.api.botsrv.updateProvider({ p: this.toAPIParams() });
  }

  /**
   * Преобразует модель бэкенда в текущую бизнес-модель.
   * 
   * При изменениях на бэкенде важно сюда так же внести изменения – такие, как
   * добавление или удаление полей.
   */
  public fromAPIResponse(dto: IProduction): void {
    this.companyName = dto.CompanyName ?? this.companyName;
    this.serviceAndProductDescription = dto.ServiceAndProductDescription ?? this.serviceAndProductDescription;
    this.productionDescription = dto.ProductionDescription ?? this.productionDescription;
    this.INN = dto.INN ?? this.INN;
    this.cityLocation = dto.CityLocation ?? this.cityLocation;
    this.phone = dto.Phone ?? this.phone;
    this.email = dto.Email ?? this.email;
    this.technologicalProcessList = dto.TechnologicalProcessList ?? this.technologicalProcessList;
  }

  /**
   * Преобразует бизнес-модель в модель бэкенда.
   * 
   * При изменениях на бэкенде важно сюда так же внести изменения – такие, как
   * добавление или удаление полей.
   */
  public toAPIParams(): IProduction {
    return omitEmptyProps({
      CompanyName: this.companyName,
      ServiceAndProductDescription: this.serviceAndProductDescription,
      ProductionDescription: this.productionDescription,
      INN: this.INN,
      CityLocation: this.cityLocation,
      Phone: this.phone,
      Email: this.email,
      TechnologicalProcessList: this.technologicalProcessList,
    });
  }
}