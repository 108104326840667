import 'reflect-metadata';
import { Container } from 'inversify';
import { InjectionKeys } from './injection.keys';
import { IAPIService, APIService } from './services/api-service';
import { IConfigService, ConfigService } from './services/config-service';
import { IErrorService, ErrorService } from './services/error-service';
import { ITelegramService, TelegramService } from './services/telegram-service';
import { IValidatorService, ValidatorService } from './services/validator-service';
import { ErrorGlobalService, IErrorGlobalService } from './services/error-global-service';
import { ProviderModel } from './models/provider-model';
import { OfferModel } from './models/offer-model';
import { OrderModel } from './models/order-model';
import { DetailModel } from './models/detail-model';
import { ContactModel } from './models/contact-model';

/**
 * Контейнер инверсий зависимостей. Все модели и сервисы должны распологаться здесь
 * и вся работа с ними должна происходить только посредством этого контейнера.
 * 
 * Таким образом достигается их связь только на основе интерфейсов, что дает гибкость
 * при замене реализаций, как пример. Одним словом, происходит ослабление связи
 * между компонентами приложения.
 */
export const container = new Container();

container.bind<IConfigService>(InjectionKeys.ConfigService).to(ConfigService).inSingletonScope();
container.bind<ITelegramService>(InjectionKeys.TelegramService).to(TelegramService).inSingletonScope();
container.bind<IAPIService>(InjectionKeys.APIService).to(APIService);
container.bind<IValidatorService>(InjectionKeys.ValidatorService).to(ValidatorService);
container.bind<IErrorService>(InjectionKeys.ErrorService).to(ErrorService);
container.bind<IErrorGlobalService>(InjectionKeys.ErrorGlobalService).to(ErrorGlobalService).inSingletonScope();

container.bind<ContactModel>(InjectionKeys.ContactModel).to(ContactModel).inSingletonScope();
container.bind<ProviderModel>(InjectionKeys.ProviderModel).to(ProviderModel).inSingletonScope();
container.bind<OfferModel>(InjectionKeys.OfferModel).to(OfferModel).inSingletonScope();
container.bind<OrderModel>(InjectionKeys.OrderModel).to(OrderModel).inSingletonScope();
container.bind<DetailModel>(InjectionKeys.DetailModel).to(DetailModel).inSingletonScope();