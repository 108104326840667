import { Typography } from '@mui/material';
import { FC, PropsWithChildren } from 'react';

import styles from './description.module.css';

/**
 * Описание текущей формы. Размещается под названием формы.
 */
export const Description: FC<PropsWithChildren> = ({ children }) => (
  <Typography className={styles.description}  >
    {children}
  </Typography>
);
