import { Box } from '@mui/material';
import { FC, PropsWithChildren } from 'react';
import styles from './wrapper.module.css';

/**
 * Контейнер, в который оборачивается форма. Растягивается во всю длину и ширину,
 * так же обеспечивает колоночную верстку.
 */
export const Wrapper: FC<PropsWithChildren> = ({ children }) => (
  <Box className={styles.wrapper}>
    {children}
  </Box>
)