import dayjs, { Dayjs } from 'dayjs';
import { Nullable } from '../../types/common';
import { InjectionKeys } from '../../injection.keys';
import { type IAPIService } from '../../services/api-service';
import { makeAutoObservable } from 'mobx';
import { inject, injectable } from 'inversify';
import { IOfferModel } from './interfaces/offer-model';
import { omitEmptyProps } from '../../helpers/omit-empty-props';
import { IBotsrvAddOfferParams, IOffer } from '../../services/api-service/client';
import type { ITelegramService } from '../../services/telegram-service';

/**
 * Бизнес-модель оффера. Является реактивной моделью, то есть
 * все изменения полей провоцируют изменение интерфейса.
 */
@injectable()
export class OfferModel implements IOfferModel {
  public applicationId = 0;
  public comment = '';
  public createdAt: Nullable<Dayjs>;
  public deliveryCost = NaN;
  public detailQuantity = 1;
  public id = 0;
  public isTollingMaterial = false;
  public productionBotUserId = 0;
  public productionId = 0;
  public rating = NaN;
  public shippingAt: Nullable<Dayjs>;
  public statusId = 0;
  public technologicalProcessList: string[] = [];
  public totalUnitCost = NaN;
  public unitServiceCost = NaN;
  public unitWeight = NaN;

  /**
   * Мемоизированный номер заказа.
   */
  private _orderId: Nullable<number>;

  @inject(InjectionKeys.APIService)
  private _apiService: IAPIService = null as any;

  @inject(InjectionKeys.TelegramService)
  private _telegramService: ITelegramService = null as any;

  public constructor() {
    makeAutoObservable(this);

    this._getParams();
  }

  /**
   * Обновляет значение поля в бизнес-модели.
   */
  public updateModel<K extends keyof IOfferModel>(key: K, value: this[K]): void {
    this[key] = value;
  }

  /**
   * Отправляет запрос в АПИ на регистрацию нового оффера, передавая все
   * заполненные данные из текущей бизнес-модели.
   * 
   * @throws Не найден идентфикатор вашего заказа
   */
  public async create(): Promise<IOffer | void> {
    if (!this._orderId) {
      alert('Не найден идентфикатор вашего заказа');

			return this._telegramService.close();
    }

    return await this._apiService.api.botsrv.addOffer(this.toAPIParams(this._orderId));
  }

  /**
   * Преобразует бизнес-модель в модель бэкенда.
   * 
   * При изменениях на бэкенде важно сюда так же внести изменения – такие, как
   * добавление или удаление полей.
   */
  public toAPIParams(orderId: number): IBotsrvAddOfferParams {
    return {
      orderId,
      of: omitEmptyProps({
        ApplicationID: this.applicationId,
        Comment: this.comment,
        CreatedAt: dayjs().toISOString(),
        DeliveryCost: this.deliveryCost,
        DetailQuantity: this.detailQuantity,
        ID: this.id,
        IsTollingMaterial: this.isTollingMaterial,
        ProductionBotUserID: this.productionBotUserId,
        ProductionID: this.productionId,
        Rating: this.rating,
        ShippingAt: this.shippingAt ? this.shippingAt.toISOString() : '',
        StatusID: this.statusId,
        TechnologicalProcessList: this.technologicalProcessList,
        TotalUnitCost: this.totalUnitCost,
        UnitServiceCost: this.unitServiceCost,
        UnitWeight: this.unitWeight,
      })
    };
  }

  /**
   * Возвращает и сохраняет такие поля из параметров URL, как applicationId,
   * id, orderId, productionBotId, productionId, statusId.
   */
  private _getParams(): void {
    const queryParameters = new URLSearchParams(window.location.search);

    const orderId = queryParameters.get('orderId');
    const applicationId = queryParameters.get('applicationId');
    const id = queryParameters.get('id');
    const productionBotUserId = queryParameters.get('productionBotUserId');
    const productionId = queryParameters.get('productionId');
    const statusId = queryParameters.get('statusId');

    this._orderId = !!orderId ? Number(orderId) : null;

    this.applicationId = !!applicationId ? Number(applicationId) : 0;
    this.id = !!id ? Number(id) : 0;
    this.productionBotUserId = !!productionBotUserId ? Number(productionBotUserId) : 0;
    this.productionId = !!productionId ? Number(productionId) : 0;
    this.statusId = !!statusId ? Number(statusId) : 0;
  }
}