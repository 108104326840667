import { makeAutoObservable } from 'mobx';
import { inject, injectable } from 'inversify';
import { InjectionKeys } from '../../injection.keys';
import { type IAPIService } from '../../services/api-service';
import { Nullable } from '../../types/common';
import { IDetailModel } from './interfaces/detail-model';
import { FileWithPath } from 'react-dropzone';
import { omitEmptyProps } from '../../helpers/omit-empty-props';
import { IBotsrvAddDetailParams, IDetail } from '../../services/api-service/client';
import { APIMethod } from '../../services/api-service/enums/api-method';

/**
 * Бизнес-модель детали. Является реактивной моделью, то есть
 * все изменения полей провоцируют изменение интерфейса.
 */
@injectable()
export class DetailModel implements IDetailModel {
  public detailComment = '';
  public detailHeight = NaN;
  public detailLength = NaN;
  public detailName = '';
  public detailQuantity = 1;
  public detailWeight = NaN;
  public detailWidth = NaN;
  public drawings: Nullable<FileWithPath[] | string[]> = null
  public materialName = '';
  public operationList: string[] = [];
  public region = '';

  /**
   * Возвращает значение, определяющее, заполненна деталь была вручную или
   * же была заполненна автоматически на основе полученной детали с бэкенда.
   */
  public isManualFill = false;
  
  /**
   * Возвращает значение, определяющее, есть ли в базе данных данная деталь
   * или нет.
   */
  public isExists = false;

  /**
   * Мемоизированный номер заказа.
   */
  private _orderId: Nullable<number>;

  @inject(InjectionKeys.APIService)
  private _apiService: IAPIService = null as any;

  public constructor() {
    makeAutoObservable(this);
  }

  /**
   * Устанавливает флаг, что бизнес-модель детали была заполненна вручную.
   */
  public setIsManualFill(): void {
    this.isManualFill = true;
  }

  /**
   * Обновляет значение поля в бизнес-модели.
   */
  public updateModel<K extends keyof IDetailModel>(key: K, value: this[K]): void {
    this[key] = value;
  }

  /**
   * Отправляет запрос в АПИ, и в случае, если пришел ответ – заполняет
   * поля бизнес-модели вернувшимеся данными.
   */
  public async get(): Promise<void> {
    const orderId = this._getOrderId();

    if (orderId) {
      const res = await this._apiService.api.botsrv.getDetail({ orderId });
  
      if (res) {
        this.fromAPIResponse(res);
      }

      this.isExists = true;
    }
  }

  /**
   * Отправляет запрос в АПИ на регистрацию новой детали, передавая все
   * заполненные данные из текущей бизнес-модели.
   * 
   * В ответе возвращает и сохраняет номер заказа.
   * 
   * Так же, после отправки запроса в АПИ на регистрацию новой детали,
   * отправляет запрос на сохранение на сервере чертежей детали (в случае, если
   * они были загружены в бизнес-модель).
   * 
   * @throws Не найден идентфикатор вашего заказа
   */
  public async create(): Promise<number> {
    const orderId = await this._apiService.api.botsrv.addDetail(this.toAPIParams());

    if (orderId && orderId > 0) {
      if (this.drawings && this.drawings.length > 0 && (this.drawings as unknown[]).every(x => typeof x !== 'string')) {
        const data = new FormData();
  
        for (let i = 0; i < this.drawings.length; i++) {
          data.append('files', this.drawings[i]);
        }
  
        await this._apiService.post(APIMethod.AddFile, { orderId: orderId, data }, true);
      }
  
      this._setOrderId(orderId);
    }

    return orderId;
  }

  /**
   * Преобразует модель бэкенда в текущую бизнес-модель.
   * 
   * При изменениях на бэкенде важно сюда так же внести изменения – такие, как
   * добавление или удаление полей.
   */
  public fromAPIResponse(dto: IDetail): void {
    this.detailComment = dto.DetailComment ?? this.detailComment;
    this.detailHeight = dto.DetailHeight ?? this.detailHeight;
    this.detailLength = dto.DetailLength ?? this.detailLength;
    this.detailName = dto.DetailName ?? this.detailName;
    this.detailQuantity = dto.DetailQuantity ?? this.detailQuantity;
    this.detailWeight = dto.DetailWeight ?? this.detailWeight;
    this.detailWidth = dto.DetailWidth ?? this.detailWidth;
    this.materialName = dto.MaterialName ?? this.materialName;
    this.drawings = dto.Drawings ?? this.drawings;
    this.operationList = dto.OperationList ?? this.operationList;
    this.region = dto.Region ?? this.region;
  }

  /**
   * Преобразует бизнес-модель в модель бэкенда.
   * 
   * При изменениях на бэкенде важно сюда так же внести изменения – такие, как
   * добавление или удаление полей.
   */
  public toAPIParams(): IBotsrvAddDetailParams {
    return {
      detail: omitEmptyProps({
        DetailComment: this.detailComment,
        DetailHeight: this.detailHeight,
        DetailLength: this.detailLength,
        DetailName: this.detailName,
        DetailQuantity: this.detailQuantity,
        DetailWeight: this.detailWeight,
        DetailWidth: this.detailWidth,
        Drawings: !this.drawings ? [] : this.drawings.length > 0 && (this.drawings as unknown[]).every(x => typeof x !== 'string') ? [] : this.drawings as string[],
        MaterialName: this.materialName,
        OperationList: this.operationList,
        Region: this.region,
      }),
    };
  }

  /**
   * Возвращает и сохраняет номер заказа из параметров URL.
   */
  private _getOrderId(): Nullable<number> {
    if (!this._orderId) {
      const queryParameters = new URLSearchParams(window.location.search);
      const orderId = queryParameters.get('orderId');
  
      this._orderId = !!orderId ? Number(orderId) : null;
    }

    return this._orderId;
  }

  /**
   * Записывает и сохраняет в параметры URL номер заказа.
   * 
   * Использует window.history.replaceState, что бы не вызывать перезагрузки
   * страницы.
   */
  private _setOrderId(orderId: number): void {
    const queryParameters = new URLSearchParams(window.location.search);
    queryParameters.set('orderId', orderId.toString());

    this._orderId = !!orderId ? Number(orderId) : null;

    window.history.replaceState({}, '', `${window.location.pathname}?${queryParameters.toString()}`);
  }
}