/* Code generated from jsonrpc schema by rpcgen v2.4.1; DO NOT EDIT. */
/* eslint-disable */
export interface IBotsrvAddContactDataParams {
  orderId: number,
  phone?: string,
  email?: string
}

export interface IBotsrvAddDetailParams {
  detail: IDetail
}

export interface IBotsrvAddOfferParams {
  orderId: number,
  of: IOffer
}

export interface IBotsrvAddOrderParams {
  orderId: number,
  order: IOrder
}

export interface IBotsrvAddProviderParams {
  p: IProduction
}

export interface IBotsrvGetDetailParams {
  orderId: number
}

export interface IBotsrvGetOrderParams {
  orderId: number
}

export interface IBotsrvNewOrderNotificationParams {
  orderID: number,
  pid: number
}

export interface IBotsrvNewWebUrlParams {
  path: string,
  tgID: number,
}

export interface IBotsrvUpdateProviderParams {
  p: IProduction
}

export interface IDetail {
  DetailComment?: string,
  DetailHeight?: number,
  DetailLength?: number,
  DetailName: string,
  DetailQuantity?: number,
  DetailWeight: number,
  DetailWidth?: number,
  Drawings: Array<string>,
  MaterialName: string,
  OperationList: Array<string>,
  Region?: string
}

export interface IOffer {
  ApplicationID: number,
  Comment?: string,
  CreatedAt?: string,
  DeliveryCost?: number,
  DetailQuantity: number,
  ID: number,
  IsTollingMaterial: boolean,
  ProductionBotUserID: number,
  ProductionID: number,
  Rating?: number,
  ShippingAt: string,
  StatusID: number,
  TechnologicalProcessList: Array<string>,
  TotalUnitCost?: number,
  UnitServiceCost?: number,
  UnitWeight?: number
}

export interface IOrder {
  ApplicationComment?: string,
  ApplicationKey: string,
  ApplicationResponseTimeout?: number,
  Email?: string,
  IsTollingMaterial: boolean,
  LimitingPrice?: number,
  OffersQuantity?: number,
  OffersReceivedCount?: number,
  Phone?: string,
  ProductionRequestedCount?: number,
  ShippingAt?: string,
  TelegramUsername?: string,
  UnitServiceCost?: number,
  UnitTotalCost?: number,
  RegionSearch: string,
  Detail: IDetail
}

export interface IProduction {
  CompanyName?: string,
  ServiceAndProductDescription?: string,
  INN?: string,
  CityLocation?: string,
  Phone?: string,
  Email?: string,
  TechnologicalProcessList: Array<string>,
  ProductionDescription?: string
}

export const factory = (send: any) => ({
  botsrv: {
    addContactData(params: IBotsrvAddContactDataParams): Promise<void> {
      return send('botsrv.AddContactData', params)
    },
    addDetail(params: IBotsrvAddDetailParams): Promise<number> {
      return send('botsrv.AddDetail', params)
    },
    addOffer(params: IBotsrvAddOfferParams): Promise<IOffer> {
      return send('botsrv.AddOffer', params)
    },
    addOrder(params: IBotsrvAddOrderParams): Promise<IOrder> {
      return send('botsrv.AddOrder', params)
    },
    addProvider(params: IBotsrvAddProviderParams): Promise<void> {
      return send('botsrv.AddProvider', params)
    },
    getDetail(params: IBotsrvGetDetailParams): Promise<IDetail> {
      return send('botsrv.GetDetail', params)
    },
    getOrder(params: IBotsrvGetOrderParams): Promise<IOrder> {
      return send('botsrv.GetOrder', params)
    },
    getProduction(): Promise<IProduction> {
      return send('botsrv.GetProduction')
    },
    newOrderNotification(params: IBotsrvNewOrderNotificationParams): Promise<void> {
      return send('botsrv.NewOrderNotification', params)
    },
    newWebUrl(params: IBotsrvNewWebUrlParams): Promise<string> {
      return send('botsrv.NewWebUrl', params)
    },
    updateProvider(params: IBotsrvUpdateProviderParams): Promise<void> {
      return send('botsrv.UpdateProvider', params)
    }
  }
})