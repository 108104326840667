import { makeAutoObservable } from 'mobx';
import { Nullable } from '../../types/common';
import { inject, injectable } from 'inversify';
import { InjectionKeys } from '../../injection.keys';
import { type IAPIService } from '../../services/api-service';
import { IContactModel } from './interfaces/contact-model';
import { omitEmptyProps } from '../../helpers/omit-empty-props';
import { IBotsrvAddContactDataParams } from '../../services/api-service/client';
import type { ITelegramService } from '../../services/telegram-service';

/**
 * Бизнес-модель контактов. Является реактивной моделью, то есть
 * все изменения полей провоцируют изменение интерфейса.
 */
@injectable()
export class ContactModel implements IContactModel {
  public email = '';
  public phone = '';
  public privacy = false;
  public terms = false;

  /**
   * Мемоизированный номер заказа.
   */
  private _orderId: Nullable<number>;

  @inject(InjectionKeys.APIService)
  private _apiService: IAPIService = null as any;

  @inject(InjectionKeys.TelegramService)
  private _telegramService: ITelegramService = null as any;

  public constructor() {
    makeAutoObservable(this);
  }

  /**
   * Обновляет значение поля в бизнес-модели.
   */
  public updateModel<K extends keyof IContactModel>(key: K, value: this[K]): void {
    this[key] = value;
  }

  /**
   * Отправляет запрос в АПИ на создание нового контакта, передавая все
   * заполненные данные из текущей бизнес-модели.
   * 
   * @throws Не найден идентфикатор вашего заказа
   */
  public async create(): Promise<void> {
    const orderId = this._getOrderId();

    if (!orderId) {
      alert('Не найден идентфикатор вашего заказа');

			return this._telegramService.close();
    }

    await this._apiService.api.botsrv.addContactData(this.toAPIParams(orderId));
  }

  /**
   * Преобразует бизнес-модель в модель бэкенда.
   * 
   * При изменениях на бэкенде важно сюда так же внести изменения – такие, как
   * добавление или удаление полей.
   */
  public toAPIParams(orderId: number): IBotsrvAddContactDataParams {
    return omitEmptyProps({
      orderId,
      email: this.email,
      phone: this.phone,
    })
  }

  /**
   * Возвращает и сохраняет номер заказа из параметров URL.
   */
  private _getOrderId(): Nullable<number> {
    if (!this._orderId) {
      const queryParameters = new URLSearchParams(window.location.search);
      const orderId = queryParameters.get('orderId');
  
      this._orderId = !!orderId ? Number(orderId) : null;
    }

    return this._orderId;
  }
}