import { FC } from 'react';
import { useI18n } from '../../core/hooks/use-i18n';
import { useInjection } from 'inversify-react';
import { InjectionKeys } from '../../core/injection.keys';
import { observer } from 'mobx-react';
import { FormColumn } from '../form-builder';
import { FormItemProps, FormItemType } from '../form-item';
import { DetailModel } from '../../core/models/detail-model';
import cities from '../../configs/cities.json';
import techProcesses from '../../configs/tech-process.json';

/**
 * Строитель формы детали.
 * 
 * В себе содержит только инъекцию бизнес-модели и конфигурацию самой формы, внутри
 * которой полями value и onChange он связывает отображение и изменение данных в форме.
 * 
 * А основной строитель форм на основе указанных параметров уже сам генерирует форму. 
 */
export const DetailFormBuilder: FC = observer(() => {
  const i18n = useI18n();
  const model = useInjection<DetailModel>(InjectionKeys.DetailModel);

  const formConfig: FormItemProps[] = [
    {
      id: 'detail-name',
      required: true,
      type: FormItemType.Text,
      label: i18n.form.detailName2,
      value: model.detailName,
      onChange: x => model.updateModel('detailName', x),
    },
    {
      id: 'material',
      required: true,
      type: FormItemType.Text,
      label: i18n.form.material,
      help: i18n.form.materialHelp,
      value: model.materialName,
      onChange: x => model.updateModel('materialName', x),
    },
    {
      id: 'operation',
      required: true,
      multiple: true,
      type: FormItemType.Autocomplete,
      options: techProcesses,
      label: i18n.form.operation,
      help: i18n.form.operationHelp,
      value: model.operationList,
      onChange: x => model.updateModel('operationList', x as string[]),
    },
    {
      id: 'detail-weight',
      type: FormItemType.Number,
      required: true,
      label: i18n.form.weight,
      unit: i18n.form.weightUnit,
      value: model.detailWeight,
      onChange: x => model.updateModel('detailWeight', x),
    },
    {
      id: 'detail-count',
      integer: true,
      type: FormItemType.Number,
      label: i18n.form.detailCount2,
      unit: i18n.form.countUnit,
      value: model.detailQuantity,
      onChange: x => model.updateModel('detailQuantity', x),
    },
    {
      id: 'file',
      type: FormItemType.File,
      value: model.drawings,
      required: true,
      onChange: x => model.updateModel('drawings', x),
    },
    {
      id: 'region',
      type: FormItemType.Autocomplete,
      label: i18n.form.regionSearch2,
      help: i18n.form.regionSearchHelp2,
      options: cities,
      value: model.region as string,
      onChange: x => model.updateModel('region', x as string),
    },
    {
      id: 'additional-comment',
      type: FormItemType.Textarea,
      label: i18n.form.additionalCommentLong,
      help: i18n.form.additionalCommentHelp,
      value: model.detailComment,
      onChange: x => model.updateModel('detailComment', x),
    },
  ];

  return <FormColumn config={formConfig} />;
});