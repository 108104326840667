import { injectable } from 'inversify';
import { IValidatorService } from './interfaces/validator-service';

@injectable()
export class ValidatorService implements IValidatorService {
  public greaterThanZero(value: number): boolean {
    return isNaN(value) || value >= 0;
  }

  public isNotEmpty(value: unknown): boolean {
    return typeof value !== 'number' ? !!value && (value as any).toString().length > 0 : value >= 0;
  }
  
  public isINN(value: string): boolean {
    return /^[\d+]{10,12}$/.test(value);
  }

  public isPhone(value: string): boolean {
    return /^(\+7|7|8)?[\s-]?\(?[0-9]{3}\)?[\s-]?[0-9]{3}[\s-]?[0-9]{2}[\s-]?[0-9]{2}$/gm.test(value);
  }
  
  public isEmail(value: string): boolean {
    return /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/gm.test(value);
  }
}