import { FC, MouseEvent, useRef } from 'react';
import { FormItemType } from '../enums/form-item-type';
import { FormItemBaseProps } from '../interfaces/form-item-base-props';
import { IconButton, Typography } from '@mui/material';
import { useI18n } from '../../../core/hooks/use-i18n';
import { Nullable } from '../../../core/types/common';
import { PropsWithClassname } from '../../../core/types/react';
import styles from '../form-item.module.css';
import { clsx } from 'clsx';
import { FileWithPath, useDropzone } from 'react-dropzone';
import { Delete, Description, FileUpload, InsertPhoto, PictureAsPdf } from '@mui/icons-material';
import { observer } from 'mobx-react';

const ACCEPTED = '*';


const isFileArray = (value: unknown[]): value is FileWithPath[] => value.every(x => typeof x !== 'string');
const isStringArray = (value: unknown[]): value is string[] => value.every(x => typeof x === 'string');

export interface FormItemFileProps extends FormItemBaseProps<Nullable<FileWithPath[] | string[]>> {
  type: FormItemType.File;
}

/**
 * Поле загрузки файлов. В случае, если по умолчанию был передан список файлов,
 * будет только отображен список этих файлов без возможности редактирования.
 * 
 * После загрузки отображает список файлов. Для загрузки можно как нажать на поле,
 * так и перетащить файлы в него.
 */
export const FormItemFile: FC<PropsWithClassname<FormItemFileProps>> = observer(({
  id, value,

  className,
  onChange,
}) => {
  const i18n = useI18n();
  const isFiles = useRef(!value || (!!value && isFileArray(value)));
  
  const handleDrop = (acceptedFiles: FileWithPath[]) => {
    onChange(acceptedFiles);
  };

  const handleRemoveFile = (index: number) => (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    if (value && isFileArray(value) && value.length > 0) {
      const newFiles = value.filter((_, i) => i !== index);

      if (newFiles.length > 0) {
        onChange(newFiles);
      } else {
        onChange(null);
      }
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop: handleDrop });

  const renderFileIcon = (fileName: string) => {
    const ext = fileName.split('.').pop()?.toLowerCase();

    if (ext === 'pdf') {
      return <PictureAsPdf />;
    } else if (ext === 'png' || ext === 'jpg' || ext === 'jpeg') {
      return <InsertPhoto />;
    } else if (ext === 'doc' || ext === 'docx') {
      return <Description />;
    } else {
      return null;
    }
  };

  const getFileName = (file: FileWithPath | string) => {
    if (typeof file === 'string') {
      return decodeURI(file.split(/(\\|\/)/g).pop()?.toLowerCase() ?? '');
    } else {
      return file.name;
    }
  };

  const handleOpenFile = (index: number) => {
    if (value) {
      if (isFileArray(value)) {
        window.open(URL.createObjectURL(value[index]), '_blank');
      } else if (isStringArray(value)) {
        window.open(value[index], '_blank')
      }
    }
  };
  
  return (
    <div className={clsx({
      [styles.fileUpload]: true, 
      [styles.fileUploadList]: value && value.length > 0, 
      [styles.fileUploadDropable]: isDragActive,
      className,
    })} {...getRootProps()}>
      {isFiles.current && <input {...getInputProps({ id, accept: ACCEPTED, multiple: true })} />}

      {value && value.length > 0 ? (
        value.map((file, i) => (
          <div
            key={getFileName(file)}
            className={styles.fileUploadListButton}
            onClick={() => handleOpenFile(i)}
          >
            <div>{renderFileIcon(getFileName(file))}</div>
            <div>{getFileName(file)}</div>
            {isFiles.current && (
              <IconButton onClick={handleRemoveFile(i)}>
                <Delete />
              </IconButton>
            )}
          </div>
        ))
      ) : (
        <div className={styles.fileUploadPlaceholder}>
          <FileUpload  />
          
          <Typography >{isDragActive ? i18n.form.fileDrag : i18n.form.file}</Typography>
        </div>
      )}
    </div>
  );
});