import { Typography } from '@mui/material';
import { FC, PropsWithChildren } from 'react';

import styles from './header.module.css';

/**
 * Заголовок формы.
 */
export const Header: FC<PropsWithChildren> = ({ children }) => (
  <Typography
    variant='h3'
    component='h1'
    className={styles.header}
  >
    {children}
  </Typography>
);
