import { injectable } from 'inversify';
import appConfig from '../../../configs/app.json';
import { IConfigService } from './interfaces/config-service';

@injectable()
export class ConfigService implements IConfigService {
  public get apiUrl(): string {
    return process.env.REACT_APP_API ?? appConfig.apiUrl;
  }

  public get apiRpcUrl(): string {
    return process.env.REACT_APP_RPC_API ?? appConfig.apiRpcUrl;
  }

  public get privacy(): string {
    return appConfig.privacy;
  }

  public get terms(): string {
    return appConfig.terms;
  }
}