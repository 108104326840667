import { injectable } from 'inversify';
import { ITelegramService } from './interfaces/telegram-service';

@injectable()
export class TelegramService implements ITelegramService {
  private _tg = window.Telegram.WebApp;

  public constructor() {
    this._setThemeColor('#ffffff');
  }

  public get user() {
    return this._tg.initDataUnsafe.user;
  }

  public close() {
    this._tg.close();
  }

  private _setThemeColor(color: `#${string}`): void {
    this._tg.themeParams.bg_color = color;
    this._tg.themeParams.secondary_bg_color = color;

    this._tg.setHeaderColor(color);
    this._tg.setBackgroundColor(color);
  }
}