import { FC } from 'react';
import { FormItemProps } from './types/form-item-props';
import { FormItemType } from './enums/form-item-type';
import { FormItemText } from './components/form-item-text';
import { FormItemFile } from './components/form-item-file';
import { FormItemCheckbox } from './components/form-item-checkbox';
import { FormItemNumber } from './components/form-item-number';
import { FormItemDate } from './components/form-item-date';
import { FormItemEmail } from './components/form-item-email';
import { FormItemPhone } from './components/form-item-phone';
import { FormItemINN } from './components/form-item-inn';
import { FormItemAutocomplete } from './components/form-item-autocomplete';
import { FormItemTextarea } from './components/form-item-textarea';
import { useI18n } from '../../core/hooks/use-i18n';

/**
 * Фабрика компонентов. На основе указанного типа в пропсах создает необходимый
 * компонент.
 * 
 * @throws Error Не указан тип компонента
 */
export const FormItemFactory: FC<FormItemProps> = props => {
  const { type } = props;

  const i18n = useI18n();

  switch (type) {
    case FormItemType.Text:
      return <FormItemText {...props} />;
    case FormItemType.Textarea:
      return <FormItemTextarea {...props} />;
    case FormItemType.Autocomplete:
      return <FormItemAutocomplete {...props} />;
    case FormItemType.INN:
      return <FormItemINN {...props} />;
    case FormItemType.Phone:
      return <FormItemPhone {...props} />;
    case FormItemType.Email:
      return <FormItemEmail {...props} />;
    case FormItemType.Date:
      return <FormItemDate {...props} />;
    case FormItemType.Number:
      return <FormItemNumber {...props} />;
    case FormItemType.Checkbox:
      return <FormItemCheckbox {...props} />;
    case FormItemType.File:
      return <FormItemFile  required={true} {...props} />;
    default:
      throw new Error(i18n.form.errors.undefinedType);
  }
};
