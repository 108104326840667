import { ChangeEvent, FC, FocusEvent, ReactNode, useEffect, useState } from 'react';
import { useInjection } from 'inversify-react';
import { FormItemType } from '../enums/form-item-type';
import { FormItemBaseProps } from '../interfaces/form-item-base-props';
import { FormItemWithHelpProps } from '../interfaces/form-item-with-help-props';
import { InjectionKeys } from '../../../core/injection.keys';
import { Box, TextField } from '@mui/material';
import { PropsWithClassname } from '../../../core/types/react';
import { Help } from '../../help';
import styles from '../form-item.module.css';
import { clsx } from 'clsx';
import InputMask from 'react-input-mask';
import { IErrorService } from '../../../core/services/error-service';
import { useMount } from '../../../core/hooks/use-mount';
import { observer } from 'mobx-react';
import { FormItemWithLabelProps } from '../interfaces/form-item-with-label-props';
import { IErrorGlobalService } from '../../../core/services/error-global-service';
import { autorun } from 'mobx';

export interface FormItemPhoneProps extends FormItemBaseProps<string>, FormItemWithLabelProps, FormItemWithHelpProps {
  type: FormItemType.Phone;
}

/**
 * Поле ввода номера телефона.
 */
export const FormItemPhone: FC<PropsWithClassname<FormItemPhoneProps>> = observer(({
  id,
  label,
  value,
  help,
  readonly = false,
  disabled = false,
  required = false,
  className,

  onChange,
}) => {
  const [, updateState] = useState({});

  const errorService = useInjection<IErrorService>(InjectionKeys.ErrorService);
  const errorGlobalService = useInjection<IErrorGlobalService>(InjectionKeys.ErrorGlobalService);

  useMount(() => errorService.init(id, required));

  useEffect(() => autorun(() => {
    if (errorGlobalService.inCheckFormFlag != null) {
      errorService.validatePhone(value);
      errorService.validateRequired(value);

      updateState({});
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [value]);

  const handleChange = ({ target }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    errorService.validatePhone(target.value);
    errorService.validateRequired(target.value);

    onChange(target.value);
  }

  const handleFocus = ({ target }: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    errorService.refresh();
    errorService.validatePhone(target.value);
  }

  const handleBlur = ({ target }: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    errorService.validatePhone(target.value);
    errorService.validateRequired(target.value);
  }

  return (
    <Box className={styles.wrapper}>
      <InputMask
        mask="+7 (999) 999-99-99"
        maskChar="_"
        value={value}
        disabled={disabled}
        readOnly={readonly}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
      >
        {(() => <TextField
          fullWidth
          type='tel'
          size='small'
          id={id}
          label={label}
          required={required}
          className={clsx(styles.textfield, className)}
          error={errorService.hasError}
          helperText={errorService.message}
        />) as unknown as ReactNode}
      </InputMask>
      
      {help
        ? <Help content={help} className={styles.help} />
        : null}
    </Box>
  );
});