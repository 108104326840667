import { FC, useState } from 'react';
import { Wrapper } from '../wrapper';
import { Header } from '../header';
import { useI18n } from '../../core/hooks/use-i18n';
import { useInjection } from 'inversify-react';
import { InjectionKeys } from '../../core/injection.keys';
import { observer } from 'mobx-react';
import { IErrorGlobalService } from '../../core/services/error-global-service';
import { useMount } from '../../core/hooks/use-mount';
import { ITelegramService } from '../../core/services/telegram-service';
import { OfferModel } from '../../core/models/offer-model';
import { OfferFormBuilder } from './offer-form-builder';
import { LoadingButton } from '@mui/lab';

/**
 * Форма создания оффера.
 * 
 * После завершения регистрации оффера вызывает метод для закрытия текущего окна.
 * 
 * Все формы работают на основе строителя форм: есть обертка, которая отвечает за
 * внешний вид + сабмиттинг, есть внутренняя форма, которая задают конфигурацию
 * для строителя форм с применением бизнес-моделей.
 */
export const OfferForm: FC = observer(() => {
  const i18n = useI18n();
  const telegramService = useInjection<ITelegramService>(InjectionKeys.TelegramService);
  const model = useInjection<OfferModel>(InjectionKeys.OfferModel);
  const errorGlobalService = useInjection<IErrorGlobalService>(InjectionKeys.ErrorGlobalService);
  const [isLoading, setIsLoading] = useState(false);

  useMount(() => () => errorGlobalService.clear());

  const handleSubmit = async () => {
    errorGlobalService.checkFormStart();

    if (!errorGlobalService.hasErrors) {
      setIsLoading(true);
      
      await model.create();

      setIsLoading(false);

      telegramService.close();
    }
  }

  return (
    <Wrapper>
      <Header>{i18n.offerRegistration.title}</Header>

      <OfferFormBuilder />
  
      <LoadingButton
        loading={isLoading}
        variant='contained'
        color='primary'
        disabled={errorGlobalService.hasErrors}
        disableElevation={true}
        sx={{ marginTop: '12px' }}
        onClick={handleSubmit}
      >
        {i18n.form.submit}
      </LoadingButton>
    </Wrapper>
  );
});
