import { inject, injectable } from 'inversify';
import { IErrorService } from './interfaces/error-service';
import { makeAutoObservable, runInAction } from 'mobx';
import { Nullable } from '../../types/common';
import { ValidatorIssueType } from '../validator-service/enums/validator-issue-type';
import ru from '../../../configs/ru.json';
import type { IValidatorService } from '../validator-service/interfaces/validator-service';
import { InjectionKeys } from '../../injection.keys';
import { Dayjs } from 'dayjs';
import type { IErrorGlobalService } from '../error-global-service';

@injectable()
export class ErrorService implements IErrorService {
  public errorType: Nullable<ValidatorIssueType> = null;

  private _isRequired = false;
  private _id = '';

  @inject(InjectionKeys.ValidatorService)
  private _validatorService: IValidatorService = null as any;

  @inject(InjectionKeys.ErrorGlobalService)
  private _errorCounterService: IErrorGlobalService = null as any;

  public constructor() {
    makeAutoObservable(this);
  }

  public get hasError(): boolean {
    return this.errorType != null;
  }

  public get message(): Nullable<string> {
    if (this.errorType != null) {
      if (this.errorType === ValidatorIssueType.Checkbox) {
        return null;
      } else {
        return ru.form.errors[this.errorType];
      }
    }

    return null;
  }

  public init(id: string, isRequired = false): void {
    this._isRequired = isRequired;
    this._id = id;

    this._errorCounterService.add(id);
  }

  public refresh(): void {
    this.errorType = null;
    this._errorCounterService.set(this._id, false);
  }

  public validateRequired(value: unknown): void {
    if (this._isRequired) {
      this._setErrorType(
        ValidatorIssueType.Empty,
        this._validatorService.isNotEmpty(value),
      );
    }
  }

  public validateEmail(value: string): void {
    this._setErrorType(
      ValidatorIssueType.Email,
      this._validatorService.isEmail(value),
    );
  }

  public validatePhone(value: string): void {
    this._setErrorType(
      ValidatorIssueType.Phone,
      this._validatorService.isPhone(value),
    );
  }

  public validateINN(value: string): void {
    this._setErrorType(
      ValidatorIssueType.INN,
      this._validatorService.isINN(value),
    );
  }

  public validateNumber(value: number): void {
    this._setErrorType(
      ValidatorIssueType.LessThanZero,
      this._validatorService.greaterThanZero(value),
    );
  }

  public validateDate(value: Nullable<Dayjs>): void {
    this._setErrorType(
      ValidatorIssueType.Date,
      !!value?.isValid(),
    );
  }

  public validateChecked(value: boolean): void {
    if (this._isRequired) {
      this._setErrorType(
        ValidatorIssueType.Checkbox,
        value,
      );
    }
  }

  private _setErrorType(type: ValidatorIssueType, condition: boolean): void {
    runInAction(() => {
      if (!condition) {
        this.errorType = type;
        this._errorCounterService.set(this._id, true);
      } else if (this.errorType === type) {
        this.errorType = null;
        this._errorCounterService.set(this._id, false);
      }
    });
  }
}