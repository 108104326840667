import { Box } from '@mui/material';
import { FC } from 'react';
import styles from './form-builder.module.css';
import { FormItemFactory, FormItemProps } from '../form-item';

interface Props {
  /**
   * Конфигурация формы. На их основе генерирует полноценную форму.
   */
  config: FormItemProps[];
}

/**
 * Строитель форм.
 */
export const FormColumn: FC<Props> = ({ config }) => (
  <Box className={styles.formColumn}>
    {config.map(props => <FormItemFactory key={props.id} {...props} />)}
  </Box>
);
