import { FC, useState } from 'react';
import { Wrapper } from '../wrapper';
import { Header } from '../header';
import { useI18n } from '../../core/hooks/use-i18n';
import { useInjection } from 'inversify-react';
import { InjectionKeys } from '../../core/injection.keys';
import { observer } from 'mobx-react';
import { IErrorGlobalService } from '../../core/services/error-global-service';
import { useMount } from '../../core/hooks/use-mount';
import { ProviderFormBuilder } from './provider-form-builder';
import { ProviderModel } from '../../core/models/provider-model';
import { ITelegramService } from '../../core/services/telegram-service';
import { LoadingButton } from '@mui/lab';

/**
 * Форма регистрации/изменения производителя.
 * 
 * При инициализации обращается к бизнес-модели с попыткой получить имеющиеся
 * в базе данных данные производителя. Если таких нет – то форма будет пустой. Если данные есть –
 * форма заполнится автоматически.
 * 
 * После завершения регистрации/изменения производителя вызывает метод для закрытия текущего окна.
 * 
 * Все формы работают на основе строителя форм: есть обертка, которая отвечает за
 * внешний вид + сабмиттинг, есть внутренняя форма, которая задают конфигурацию
 * для строителя форм с применением бизнес-моделей.
 */
export const ProviderForm: FC = observer(() => {
  const i18n = useI18n();
  const telegramService = useInjection<ITelegramService>(InjectionKeys.TelegramService);
  const model = useInjection<ProviderModel>(InjectionKeys.ProviderModel);
  const errorGlobalService = useInjection<IErrorGlobalService>(InjectionKeys.ErrorGlobalService);
  const [isLoading, setIsLoading] = useState(false);

  useMount(() => {
    const fetch = async () => {
      await model.get();
    };

    fetch();

    return () => errorGlobalService.clear();
  });

  const handleSubmit = async () => {
    errorGlobalService.checkFormStart();

    if (!errorGlobalService.hasErrors) {
      setIsLoading(true);

      if (model.isExists) {
        await model.change();
      } else {
        await model.create();
      }

      setIsLoading(false);
      telegramService.close();
    }
  }

  return (
    <Wrapper>
      <Header>{i18n.providerRegistration.title}</Header>

      <ProviderFormBuilder />
  
      <LoadingButton
        loading={isLoading}
        variant='contained'
        color='primary'
        disabled={errorGlobalService.hasErrors}
        disableElevation={true}
        sx={{ marginTop: '12px' }}
        onClick={handleSubmit}
      >
        {i18n.form.submit}
      </LoadingButton>
    </Wrapper>
  );
});
