import { FC, ReactNode, useEffect } from 'react';
import { FormItemType } from '../enums/form-item-type';
import { FormItemBaseProps } from '../interfaces/form-item-base-props';
import { FormItemWithHelpProps } from '../interfaces/form-item-with-help-props';
import { Box, Checkbox, FormControlLabel, Typography, checkboxClasses } from '@mui/material';
import { PropsWithClassname } from '../../../core/types/react';
import { Help } from '../../help';
import styles from '../form-item.module.css';
import { theme } from '../../../theme';
import { clsx } from 'clsx';
import { useInjection } from 'inversify-react';
import { IErrorService } from '../../../core/services/error-service';
import { InjectionKeys } from '../../../core/injection.keys';
import { useMount } from '../../../core/hooks/use-mount';
import { FormItemWithLabelProps } from '../interfaces/form-item-with-label-props';
import { observer } from 'mobx-react';
import { IErrorGlobalService } from '../../../core/services/error-global-service';
import { autorun } from 'mobx';

export interface FormItemCheckboxProps extends FormItemBaseProps<boolean>, FormItemWithLabelProps<ReactNode>, FormItemWithHelpProps {
  type: FormItemType.Checkbox;
}

/**
 * Обычный чекбокс.
 */
export const FormItemCheckbox: FC<PropsWithClassname<FormItemCheckboxProps>> = observer(({
  id,
  label,
  value,
  help,
  disabled = false,
  required = false,
  className,

  onChange,
}) => {
  const errorColor = { color: theme.palette.error.main };

  const errorService = useInjection<IErrorService>(InjectionKeys.ErrorService);
  const errorGlobalService = useInjection<IErrorGlobalService>(InjectionKeys.ErrorGlobalService);

  useMount(() => errorService.init(id, required));

  useEffect(() => autorun(() => {
    if (errorGlobalService.inCheckFormFlag != null) {
      errorService.validateChecked(value);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [value]);

  useMount(() => errorService.init(id, required));

  const handleChange = () => {
    errorService.validateChecked(!value);

    onChange(!value);
  }

  return (
    <Box className={className}>
      <FormControlLabel
        disabled={disabled}
        label={(
          <Typography
            className={clsx(styles.wrapper, styles.checkboxLabel)}
            sx={!errorService.hasError ? {} : errorColor}
          >
            <span>{label}</span>

            {help
              ? <Help content={help} className={clsx(styles.help, styles.helpCheckbox)} />
              : null}
          </Typography>
        )}
        control={(
          <Checkbox
            id={id}
            checked={value}

            onChange={handleChange}

            sx={!errorService.hasError ? {} : {
              [`&, &.${checkboxClasses.checked}`]: errorColor,
            }}
          />
        )}
      />
    </Box>
  );
});