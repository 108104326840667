import { ChangeEvent, FC, FocusEvent, useEffect } from 'react';
import { useInjection } from 'inversify-react';
import { FormItemType } from '../enums/form-item-type';
import { FormItemBaseProps } from '../interfaces/form-item-base-props';
import { FormItemWithHelpProps } from '../interfaces/form-item-with-help-props';
import { InjectionKeys } from '../../../core/injection.keys';
import { Box, TextField } from '@mui/material';
import { PropsWithClassname } from '../../../core/types/react';
import { Help } from '../../help';
import styles from '../form-item.module.css';
import { clsx } from 'clsx';
import { IErrorService } from '../../../core/services/error-service';
import { useMount } from '../../../core/hooks/use-mount';
import { observer } from 'mobx-react';
import { FormItemWithLabelProps } from '../interfaces/form-item-with-label-props';
import { IErrorGlobalService } from '../../../core/services/error-global-service';
import { autorun } from 'mobx';

export interface FormItemTextProps extends FormItemBaseProps<string>, FormItemWithLabelProps, FormItemWithHelpProps {
  type: FormItemType.Text;
}

/**
 * Однострочное поле ввода текста.
 */
export const FormItemText: FC<PropsWithClassname<FormItemTextProps>> = observer(({
  id,
  label,
  value,
  help,
  readonly = false,
  disabled = false,
  required = false,
  className,

  onChange,
}) => {
  const errorService = useInjection<IErrorService>(InjectionKeys.ErrorService);
  const errorGlobalService = useInjection<IErrorGlobalService>(InjectionKeys.ErrorGlobalService);

  useMount(() => errorService.init(id, required));

  useEffect(() => autorun(() => {
    if (errorGlobalService.inCheckFormFlag != null) {
      errorService.validateRequired(value);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [value]);

  const handleChange = ({ target }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    errorService.validateRequired(target.value);

    onChange(target.value);
  }

  const handleFocus = () => errorService.refresh();

  const handleBlur = ({ target }: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    errorService.validateRequired(target.value);
  }

  return (
    <Box className={styles.wrapper}>
      <TextField
        fullWidth
        type='text'
        size='small'
        id={id}
        label={label}
        disabled={disabled}
        required={required}
        className={clsx(styles.textfield, className)}
        error={errorService.hasError}
        helperText={errorService.message}
        value={value}

        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}

        InputProps={{ readOnly: readonly }}
      />
      
      {help
        ? <Help content={help} className={styles.help} />
        : null}
    </Box>
  );
});