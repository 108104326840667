import { inject, injectable } from 'inversify';
import { IAPIService } from './interfaces/api-service';
import { InjectionKeys } from '../../injection.keys';
import type { IConfigService } from '../config-service/interfaces/config-service';
import { Nullable } from '../../types/common';
import { v4 } from 'uuid';
import { JSONrpcBody } from './interfaces/json-rpc-body';
import { RPCApiMethod } from './enums/rpc-api-method';
import { factory } from './client';
import { APIFactory } from './types/api-factory';
import { RPCApiResponse } from './interfaces/rpc-api-response';
import { APIMethod } from './enums/api-method';
import { APIRequest } from './interfaces/api-request';
import type { ITelegramService } from '../telegram-service';

@injectable()
export class APIService implements IAPIService {
	private _token: Nullable<string>;
	private _api: APIFactory;

	@inject(InjectionKeys.ConfigService)
	private _configService: IConfigService = null as any;

	@inject(InjectionKeys.TelegramService)
	private _telegramService: ITelegramService = null as any;

	public constructor() {
		this._api = factory(this._send);
	}

	public get api(): APIFactory {
		return this._api;
	}

	public async post<T extends BodyInit>(endpoint: APIMethod, params: APIRequest<T>, isMultpart?: boolean): Promise<unknown | void> {
		try {
      const res = await fetch(`${this._configService.apiUrl}/${endpoint}/${params.orderId ?? ''}`, {
				method: 'POST',
        headers: this._createHeaders(isMultpart),
				body: isMultpart ? params.data : JSON.stringify(params.data),
      });

			const apiResponse = await res.json();

			if (apiResponse && apiResponse.result) {
				return apiResponse.result;
			}
    } catch (err) {
      console.error(err);

			this._telegramService.close();
    }
	}

	private _send = async <T, R>(endpoint: RPCApiMethod, data?: T): Promise<R | void> => {
		try {
      const res = await fetch(this._configService.apiRpcUrl, {
				method: 'POST',
        headers: this._createRPCHeaders(),
				body: JSON.stringify(this._createRPCBody(endpoint, data != null ? data : {})),
      });

			const apiResponse = await res.json() as unknown as RPCApiResponse<R>;

			if (apiResponse && apiResponse.result) {
				return apiResponse.result;
			}
    } catch (err) {
      console.error(err);

			this._telegramService.close();
    }
	}

	private _getToken(): string {
		if (!this._token) {
			const queryParameters = new URLSearchParams(window.location.search);
	
			this._token = queryParameters.get('token');
		}

		return this._token as string;
	}

	private _createHeaders(isMultipart = false): Record<string, string> {
		return {
			'Authorization': `Bearer ${this._getToken()}`,
			// 'Content-Type': isMultipart ? 'multipart/form-data' : 'application/json',
		};
	}

	private _createRPCHeaders(): Record<string, string> {
		return {
			'Authorization': `Bearer ${this._getToken()}`,
			'Content-Type': 'application/json',
		};
	}

	private _createRPCBody(method: RPCApiMethod, body = {}): JSONrpcBody {
		return {
			id: v4(),
			jsonrpc: '2.0',
			method,
			params: body
		};
	}
}